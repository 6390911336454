@media only screen and (max-width: 600px) {
    .estadoAfip1 {
        text-align: center
    }

    .estadoAfip2 {
        text-align: center
    }
}

@media only screen and (min-width: 600px) {
    .estadoAfip1 {
        text-align: right
    }

    .estadoAfip2 {
        text-align: left
    }
}