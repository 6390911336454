.shimmer{
background-image: linear-gradient(
    90deg,
    #EEEEEE 0%, #EEEEEE 40%,
    #DDDDDD 50%, #DDDDDD 55%,
    #EEEEEE 65%, #EEEEEE 100%
);

background-size: 400%;
animation: shimmer 1500ms infinite;
}

.noshimmer td{
    padding-top: 5px;
    padding-bottom: 5px;
}

.shimmer{
    width: 70%;
    padding-top: 20px;
    padding-bottom: 20px;
}

@keyframes shimmer {
    from {background-position: 100% 100%;}
    to {background-position: 0 0;}
}